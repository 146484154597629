import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-5">
            <div className="col mb-3">
              <a
                href="/"
                className="d-flex align-items-center mb-3 text-decoration-none h-100 flex-column
      justify-content-center"
              >
                <img src="assets/logo.svg" height={140} alt="Votex.fi" />
              </a>
            </div>

            <div className="col mb-3"></div>

            <div className="col mb-3 row-col-50">
              <h5>Resources</h5>
              <ul className="nav flex-column">
                <li className="">
                  <Link to="/disclaimer" className="">
                    Disclaimer
                  </Link>
                </li>
                <li className="">
                  <Link to="/privacy" className="">
                    Privacy Policy
                  </Link>
                </li>
                <li className="">
                  <a
                    href="https://docs.votex.fi"
                    target={"_blank"}
                    className=""
                    rel="noreferrer"
                  >
                    Docs
                  </a>
                </li>
              </ul>
            </div>

            <div className="col mb-3 row-col-50">
              <h5>Social Media</h5>
              <ul className="nav flex-column">
                <li className="">
                  <a
                    href="https://twitter.com/votexfi"
                    target={"_blank"}
                    className=""
                    rel="noreferrer"
                  >
                    Twitter
                  </a>
                </li>
              </ul>
            </div>

            <div className="col mb-3">
              <h5>Contact</h5>
              <ul className="nav flex-column">
                <li className="mb-3">
                  <span className="mb-0">General Inquiries:</span>
                </li>
                <li className="">
                  <a href="mailto:info@votex.fi" className="">
                    info@votex.fi
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row row-cols-12">
            <div className="disclaimer">
              <div>
                VoteX is a community analytics tool and doesn’t generate any
                revenue. If you enjoy our services, please consider a donation
                to the following address &#10084;
              </div>
              <div className="address">
                0x485539d4570372870608c5223BEffa9E8303E469
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
