import { Link } from "react-router-dom";
import { appLink } from "../constants";

const Header = () => {
  const homeNav = () => {
    if (document.body.classList.contains("home-sm")) {
      document.body.classList.remove("home-sm");
    } else {
      document.body.classList.add("home-sm");
    }
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg transition-all py-lg-4">
        <div className="container-fluid">
          <div className="navbar-brand">
            <img src="assets/votex-logo.svg" alt="" />
          </div>
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => {
              homeNav();
            }}
          >
            <span className="iconify" data-icon="humbleicons:bars"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="d-lg-none d-block text-end p-4">
              <a
                href="javascript:void(0)"
                onClick={() => {
                  homeNav();
                }}
                className="cross-btn"
              >
                <span className="iconify" data-icon="akar-icons:cross"></span>
              </a>
            </div>
            <ul className="navbar-nav px-5 ps-md-5 ms-md-5 mb-2 w-100 align-items-center gap-4 mb-lg-0"></ul>
            <div className="ms-lg-5 px-lg-0 px-5">
              <a
                href={appLink}
                target={"_blank"}
                className="connect-btn mt-lg-0 mt-4"
                rel="noreferrer"
              >
                Launch App
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
