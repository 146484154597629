import React from "react";
import { appLink } from "../constants";

const Home = () => {
  return (
    <div>
      {/* Banner  */}
      <section className="banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 z-1">
              <h1 className="mb-5">
                Votex - A DeFi <br className="d-md-block d-npne" />
                Voting Aggregator
              </h1>
              <p className="mb-5">
                All DeFi bribes aggregated under a single platform. Discover{" "}
                <br className="d-md-block d-npne" /> the best opportunities for
                the #RealestYield of DeFi
              </p>
              <div className="gap-4 d-flex justify-content-md-start mb-5">
                <a
                  href={appLink}
                  target="_blank"
                  className="btnPrimary"
                  rel="noreferrer"
                >
                  Learn More
                </a>
              </div>
              <p className="mb-2">
                Built by{" "}
                <a href="http://multifarm.fi/" target="_blank" rel="noreferrer">
                  <img
                    src="assets/logo-light.svg"
                    height={25}
                    alt="Multifarm logo"
                  />
                </a>
              </p>
              <p>
                With a Grant from{" "}
                <a
                  href="https://paladin.vote/#/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="assets/paladin-logo-white.svg"
                    height={20}
                    alt="Paladin logo"
                    className="px-1"
                  />
                </a>
              </p>
            </div>
            <div className="col-lg-4 offset-lg-1 col-10 mx-auto mt-lg-0 mt-5">
              <img src="assets/banner.png" className="w-100" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section id="ecosys" className="px-md-4">
        <h2 className="mb-4">SUPPORTED ECOSYSTEMS</h2>
        <div className="eco mb-5">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-3 mb-md-0 mb-5">
                <div className="icon">
                  <img src="assets/e1.svg" alt="" />
                </div>
                <h3>CURVE FINANCE</h3>
              </div>
              <div className="col-md-3 mb-md-0 mb-5">
                <div className="icon">
                  <img src="assets/e2.svg" alt="" />
                </div>
                <h3>BALANCER</h3>
              </div>
              <div className="col-md-3 mb-md-0 mb-5">
                <div className="icon">
                  <img src="assets/e3.svg" alt="" />
                </div>
                <h3>VELODROME</h3>
              </div>
              <div className="col-md-3">
                <div className="icon">
                  <img src="assets/e4.svg" alt="" />
                </div>
                <h3>FRAX</h3>
              </div>
            </div>
          </div>
        </div>

        <h2 className="mb-4">SUPPORTED PROTOCOLS</h2>
        <div className="eco mb-5">
          <div className="container-fluid">
            <div className="row align-items-center justify-content-evenly">
              <div className="col-md-2 mb-md-0 mb-5">
                <div className="icon">
                  <img src="assets/p1.svg" alt="" />
                </div>
                <h3>HIDDEN HAND</h3>
              </div>
              <div className="col-md-2 mb-md-0 mb-5">
                <div className="icon">
                  <img src="assets/p2.svg" alt="" />
                </div>
                <h3>PALADIN</h3>
              </div>
              <div className="col-md-2 mb-md-0 mb-5">
                <div className="icon">
                  <img src="assets/yearn.png" alt="" />
                </div>
                <h3>YBRIBES</h3>
              </div>
              <div className="col-md-2 mb-md-0 mb-5">
                <div className="icon">
                  <img src="assets/votium.png" alt="" />
                </div>
                <h3>VOTIUM</h3>
              </div>
              <div className="col-md-2 mb-md-0 mb-5">
                <div className="icon">
                  <img src="assets/stake.png" alt="" />
                </div>
                <h3>VOTEMARKET</h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="roadmap">
          <div className="e-banner">
            <div className="container-fluid pe-lg-0">
              <div className="row align-items-center">
                <div className="col-lg-5">
                  <h2>EFFICIENT</h2>
                  <p>
                    Bribes are the best and most efficient way to attract
                    liquidity DeFi has come up with
                  </p>
                  <div className="">
                    <a
                      href={appLink}
                      target="_blank"
                      className="btnPrimary"
                      rel="noreferrer"
                    >
                      Launch App
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 offset-lg-1 pe-lg-0 mt-lg-0 mt-5">
                  <img
                    src="assets/efficient.png"
                    className="img-fluid"
                    alt=""
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="s-banner">
            <div className="container-fluid pe-lg-0">
              <div className="row align-items-center">
                <div className="col-lg-5">
                  <h2>SUSTAINABLE</h2>
                  <p>
                    As they are very efficient, protocols consistently use
                    bribes to acquire TVL which creates a constant stream of
                    revenue for veTOKEN holders
                  </p>
                  <div className="">
                    <a
                      href={appLink}
                      target="_blank"
                      className="btnPrimary"
                      rel="noreferrer"
                    >
                      Launch App
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 offset-lg-1 pe-lg-0 mt-lg-0 mt-5">
                  <img
                    src="assets/sustainable.png"
                    className="img-fluid"
                    alt=""
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="u-banner">
            <div className="container-fluid pe-lg-0">
              <div className="row align-items-center">
                <div className="col-lg-5">
                  <h2>UNCORRELATED RETURNS</h2>
                  <p>
                    Bribes are the least uncorrelated yields compared to other
                    DeFi opportunities
                  </p>
                  <div className="">
                    <a
                      href={appLink}
                      target="_blank"
                      className="btnPrimary"
                      rel="noreferrer"
                    >
                      Launch App
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 offset-lg-1 pe-lg-0 mt-lg-0 mt-5">
                  <img
                    src="assets/ureturns.png"
                    className="img-fluid"
                    alt=""
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="l-banner">
            <div className="container-fluid pe-lg-0">
              <div className="row align-items-center">
                <div className="col-lg-5">
                  <h2>LOW RISK</h2>
                  <p>They carry a low risk while providing a high return.</p>
                  <div className="">
                    <a
                      href={appLink}
                      target="_blank"
                      className="btnPrimary"
                      rel="noreferrer"
                    >
                      Launch App
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 offset-lg-1 pe-lg-0 mt-lg-0 mt-5">
                  <img
                    src="assets/lowRisk.png"
                    className="img-fluid"
                    alt=""
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
