import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';

const Disclaimer = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
     <div className="bg-grad pb-5">
      <div className="container mb-5">
        <div className="row justify-content-md-center">
          <div className="col-md-9">
          <div className="">
              <h1 className="post-title">Disclaimer</h1>
              <h2 className="post-title">Important Notice:</h2>
            </div>
            <h3>1. General Notice</h3>
            <p>All content provided herein our website, hyperlinked sites, associated applications, forums, blogs, social media accounts and other platforms (“Site”) is for your general information only, procured primarily from third party sources.</p>
            <p>We make no warranties of any kind in relation to our content and services (such as token swap functionalities,staking services, etc.), including but not limited to accuracy, security and updatedness.</p>
            <p>No part of the content and services that we provide constitutes financial advice, legal advice or any other form of advice meant for your specific reliance for any purpose, nor any dealing in (or promotion of) securities for which a licence is required from the respective country/jurisdiction.</p>
            <p>Any use or reliance on our content and services is solely at your own risk and discretion. You should conduct your own research, review, analyse and verify our content and services before relying on or using them. Trading and/or Yield Farming activities is a highly risky activity that can lead to major losses, please therefore consult your financial advisor before making any decision.</p>
            <p>No content on our Site is meant to be a solicitation or offer. Please conduct your own research.</p>

            <h3>2. Disclaimer Clarification</h3>
            <p>Before using this site, please make sure that you note the following important information:</p>

            <h3>2.1. Do Your Own Research</h3>
            <p>Our content is intended to be used and must be used for information and education purposes only. It is very important to do your own analysis before making any investment based on your own personal circumstances. You should take independent financial advice from a professional in connection with, or independently research and verify, any information that you find on our Website and wish to rely upon, whether for the purpose of making an investment decision or otherwise.</p>
          
            <h3>2.2 No Investment Advice</h3>
            <p>Our Website is a yield farming data portal and content provider. Multifarm.fi is not a broker/dealer, we are not an investment advisor, we have no access to non-public information about yield farms, and this is not a place for the giving or receiving of financial advice, advice concerning investment decisions or tax or legal advice.</p>
            <p>We are not regulated by the Financial Services Authority. No content on the site constitutes - or should be understood as constituting - a recommendation to enter in any crypto transactions or to engage in any of the investment strategies presented in our site content.</p>
            <p>We do not provide personalised recommendations or views as to whether a yield farm or investment approach is suited to the financial needs of a specific individual.</p>

            <h3>2.3 Individual Empowerment, Not Hand-Holding</h3>
            <p>Where we present investment screens, the results should only be treated as candidates for further research, not as a buy list or set of recommendations. Screening may help to narrow a search based on pre-defined criteria but it is not a substitute for independent research reflecting your individual criteria for investing/trading.</p>
          
            <h3>2.4 No Reliance</h3>
            <p>Accordingly, we will not be liable, whether in contract, tort (including negligence) or otherwise, in respect of any damage, expense or other loss you may suffer arising out of such information or any reliance you may place upon such information.</p>
            <p>Any arrangements between you and any third party contacted via the Website are at your sole risk.</p>

            <h3>2.5 Investment Warnings</h3>
            <p>We would like to draw your attention to the following important investment warnings.</p>
            <p>The value of investments and the income derived from them can go down as well as up. Yield farming is a high risk proposition and you can lose all your money invested due to rug pulls, their site going down or similar circumstances.</p>
            <p>Past performance is not indication of future a guide to future performance success</p> 

              <h4>Dashboard Disclaimer for Third Party Websites</h4>
              <p>The data, statements and information presented in this dashboard or Multifarm Dashboards on other websites, are for informational purposes only, and it do not constitute financial or investment advice.</p>
              <p>Further, the financial statements presented have not undergone a comprehensive financial statement audit from a third-party professional accounting firm. As such, there may exist errors or inaccuracies that materially misstate the financial statements. We are not responsible for any financial losses or adverse outcomes that may result from making investment decision based on the data presented on these dashboards.</p>
              <p>Further, by viewing those dashboards, you agree that the statements in those dashboards do not constitute a representation, warranty or guaranty regarding any matter, and you are not relying thereon, but will conduct your own due diligence.</p>
              <p>You hereby release, waive and relinquish any and all claims, causes of action and disputes against Multifarm.fi, Third Party Websites/Protocols/DAOs, its contributors, and the creators of those dashboards.</p>

                <h6>DASHBOARDS BUILT BY MULTIFARM.FI DO NOT CONSTITUTE ADVICE FOR ANY PURPOSE.</h6>

                <p>All information has been prepared using community members owned Dune Dashboards, DAO Zapper bundles and other public information from the internet.</p>
                <h6>ACCORDINGLY, WE RECOMMEND THAT YOU DO NOT RELY ON, AND DO NOT MAKE ANY FINANCIAL DECISION OR INVESTMENT BASED ON, THE STATEMENTS CONTAINED IN THOSE DASHBOARDS.</h6>
                
        </div>
        </div>
      </div>
      </div>
    </>
  )
}

export default Disclaimer