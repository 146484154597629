import React from 'react'

const ComingSoon = () => {
  return (
    <div>
        <div className="coming">
        <h1>
            Coming Soon
            </h1>
        </div>
    </div>
  )
}

export default ComingSoon